import api from '../../utils/api'

/**
 * 埋点
 * @param {*} payload
 * @returns
 */
export async function articles_stay (payload) {
  return api.post(`/api/v1/mp/articles/stays`, payload)
}





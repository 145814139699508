<template>
  <div class="view">
    <g-loading :loading="loading" />
    <van-nav-bar
      title="表单预览"
      left-arrow
      @click-left="onBack"
      v-if="source === 'h5'"
    />
    <div v-if="title" class="title">{{ title }}</div>
    <div class="content">
      <div class="numIndex"></div>
      <div v-for="(currentItem, index) in taskList" :key="index">
        <Item
          v-for="(obj, index2) in currentItem"
          :key="index2"
          :data="obj"
          :role="role"
        />
      </div>
    </div>

    <div class="footer">
      <div @click="submit" class="foot-btn">确认提交</div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex"
import Item from "../task/Item2"
import { getImgUrl, uploadImg } from "../task/service"
import { articles_stay } from "./service"
import { getSession } from "../../utils/session"

export default {
  data() {
    return {
      role: undefined,
    }
  },
  computed: {
    ...mapState("commonData", {
      source: (state) => state.source,
      start_time: (state) => state.start_time,
    }),
    ...mapState("task", {
      taskList: (state) => {
        // 显示隐藏
        let showArr = []
        for (const obj of state.taskList) {
          for (const obj2 of obj) {
            if (
              !obj2.question.isVisible &&
              !showArr.find((index) => index === obj2.frontend_id)
            )
              continue // 父元素隐藏的不显示
            if (
              (obj2.type === 1 || obj2.type === 2) &&
              obj2.value &&
              obj2.value.length > 0
            ) {
              for (let value of obj2.value) {
                if (obj2.question.options && obj2.question.options.length > 0) {
                  for (let item of obj2.question.options) {
                    if (item.value === value) {
                      if (item.visibles) showArr = showArr.concat(item.visibles)
                      break
                    }
                  }
                }
              }
            }
            if (obj2.type === 4 || obj2.type === 3) {
              if (obj2.question.valuable && obj2.value) {
                showArr = showArr.concat(obj2.question.valuable)
              }
              if (obj2.question.noValue && !obj2.value) {
                showArr = showArr.concat(obj2.question.noValue)
              }
            }
          }
        }
        return state.taskList
          .map((obj) => {
            return obj.filter(
              (obj2) =>
                obj2.question.isVisible ||
                showArr.find((index) => index === obj2.frontend_id)
            )
          })
          .filter((obj) => obj && obj.length > 0)
      },
      allData: (state) => state.allData,
      loading: (state) => state.loading,
    }),
    title() {
      return this.allData.content && this.allData.content.name
    },
  },
  components: {
    Item,
  },
  created() {
    this.role = this.$route.query.role
  },
  methods: {
    ...mapActions("task", ["submitData"]),
    ...mapMutations("task", ["showLoading"]),
    onBack() {
      this.$router.go(-1)
    },
    async submit() {
      this.showLoading()
      const data = this.taskList
      const result = []
      for (const obj of data) {
        for (const obj2 of obj) {
          const question_id = obj2.id
          const frontend_id = obj2.frontend_id
          const type = obj2.type
          let value = obj2.value
          if (obj2.type === 6 && /^data:image/g.test(value)) {
            const { data } = await getImgUrl({
              file: value,
              type: 9,
            })
            value = data.url
          }
          if (obj2.value && obj2.type === 11) {
            let arr = []
            for (let i of obj2.value) {
              if (typeof i === "object") {
                const fd = new FormData()
                fd.append("file", i)
                const { data } = await uploadImg(fd)
                arr.push(data.host + "/" + data.thumb_image)
              } else {
                arr.push(i)
              }
            }
            value = arr
          }
          if (obj2.type === 8 && obj2.value) {
            value = obj2.value.map((i) => {
              i = JSON.stringify(i)
              return i
            })
          }
          let answer = []
          if (toString.call(value) === "[object Array]") {
            answer = value
          } else if (value === 0 || value) {
            answer = [value]
          } else {
            answer = []
          }
          result.push({
            question_id,
            frontend_id,
            label_id: obj2.label_id,
            type,
            answer,
          })
        }
      }
      this.submitData({
        msg_id: this.$route.query.msg_id,
        id: this.$route.query.id,
        patient_id: this.$route.query.patient_id,
        result,
      })
      if (this.source !== "h5" && this.$route.query.msg_id) {
        this.post_mile()
      }
    },
    async post_mile() {
      let msg_id = this.$route.query.msg_id
      await articles_stay({
        msg_id: msg_id,
        url: this.$route.fullPath,
        stay_mills: new Date().getTime() - this.start_time,
        user_id: getSession()?.user_id,
      })
    },
  },
}
</script>
<style scoped>
.view {
  /*border-top: 1px solid rgba(60,130,255,1);*/
  display: flex;
  flex-direction: column;
  background: #f7faff;
  height: 100%;
  font-size: 13px;
}
.content {
  flex: 1;
  padding: 0px 0px 12px;
  overflow: auto;
  border-radius: 15px 15px 0px 0px;
}
.title {
  font-size: 21px;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
  padding: 12px 18px 12px 18px;
}
.footer {
  height: 80px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background: #ffffff;
  & .foot-btn {
    width: 80%;
    height: 36px;
    background: #1890ff;
    margin: 10px auto 0;
    border-radius: 18px;
    line-height: 36px;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
  }
}
.numIndex {
  font-size: 16px;
  color: #333;
  font-weight: bold;
  padding: 16px 18px;
  line-height: 1;
  display: flex;
}
</style>
